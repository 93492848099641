import Form from 'antd/es/form'
import styled, { createGlobalStyle } from 'styled-components'

import { datePikerPopupClassName } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/classNames'
import { searchFormSizes } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import CalendarIcon from '@iconscout/unicons/svg/line/calender.svg'
import { appearFromLeft, appearFromTop, zoomAppear } from '@styles/animations/popups/appear'
import { disappearToLeft, disappearToTop, zoomDisappear } from '@styles/animations/popups/disappear'
import { datePickerStyles, rangePickerTightStyles } from '@styles/datepicker/datepicker'
import { captionSmallStyles } from '@styles/typography/caption'
import { textLargeMediumStyles } from '@styles/typography/text'
import { title5Styles, title7Styles } from '@styles/typography/title'

export const DateInputGlobalStyles = createGlobalStyle`
  .${datePikerPopupClassName} {
    ${datePickerStyles};
    ${rangePickerTightStyles};

    &.ant-picker-dropdown:is(.ant-slide-up-enter.ant-slide-up-enter-active, .ant-slide-up-appear.ant-slide-up-appear-active).right {
      ${appearFromLeft};
    }

    &.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.right {
      ${disappearToLeft};
    }

    &.ant-picker-dropdown:is(.ant-slide-up-enter.ant-slide-up-enter-active, .ant-slide-up-appear.ant-slide-up-appear-active).bottom {
      ${appearFromTop};
    }

    &.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.bottom {
      ${disappearToTop};
    }

    .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content tbody .ant-picker-cell-inner:hover:after {
        z-index: -1;
     }
      
    .ant-picker-ranges, .ant-picker-time-panel {
        display: none;
    } 
      

    ${p => p.theme.mediaQueries.mobile} {
      inset: 0 !important;
      width: 100%;

      &.ant-picker-dropdown:is(.ant-slide-up-enter.ant-slide-up-enter-active, .ant-slide-up-appear.ant-slide-up-appear-active):is(.right, .bottom) {
        ${zoomAppear};
      }

      &.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active:is(.right, .bottom) {
        ${zoomDisappear};
      }

      .ant-picker-panel-container {
          grid-template-rows: auto 1fr;
          overflow-y: auto;
      }
    }
  }
`

export const WrapperDateInput = styled.div`
  position: relative;
`

export const DateLabel = styled(Form.Item)<{
  $compactLabelView?: boolean
  $departureLabel: string
  $isPopoverOpened?: boolean
  $returnLabel: string
}>`
  height: ${searchFormSizes.fieldHeightDesktop}px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  .ant-picker-panel-container {
    pointer-events: ${p => (p.$isPopoverOpened ? 'auto' : 'none')};
  }

  .ant-form-item-row {
    width: 100%;
    max-width: 344px;
  }

  .ant-picker-active-bar {
    display: none;
  }

  .ant-picker-time-panel {
    display: none;
  }

  .ant-picker-input:nth-of-type(3) {
    input[date-range='end'][value=''] {
      background: ${p => p.theme.colors.backgroundContainer};
      border-radius: ${p => p.theme.borderRadius.default};
      box-shadow: ${p => p.theme.shadows.button};
      text-align: center;
      padding: 12px;

      &::placeholder {
        text-transform: uppercase;
        color: ${p => p.theme.colors.primary};
        ${title7Styles};
      }
    }

    input[date-range='end'][value='']:disabled {
      &::placeholder {
        opacity: 0.3;
      }
    }
  }

  .ant-picker {
    padding: 0;

    input {
      ${title5Styles};
      color: ${p => p.theme.colors.fontMain};
      caret-color: transparent;
      cursor: pointer;
      margin-block: -40px -20px;
      margin-inline: -20px 0;
      padding-block: 40px 20px;
      padding-inline: 20px 0;
      width: auto;

      &::placeholder {
        color: ${p => p.theme.colors.fontMain};
      }
    }

    &-clear {
      color: ${p => p.theme.colors.fontSecondary};
      background: transparent;

      &:hover {
        color: ${p => p.theme.colors.fontSecondary};
        background: transparent;
      }
    }

    &.ant-picker-status-error input::placeholder {
      color: ${p => p.theme.colors.red};
    }
  }

  :where(.ant-picker-range${p => (p.$compactLabelView ? ', .ant-picker:not(.ant-picker-range)' : '')})
    .ant-picker-input {
    display: grid;
    grid-gap: 3px;

    &:before {
      ${captionSmallStyles};
      display: block;
      content: ${p => `'${p.$departureLabel}'`};
      position: ${p => (p.$compactLabelView ? 'absolute' : 'relative')};
      top: ${p => (p.$compactLabelView ? '3px' : '-4px')};
      color: ${p => p.theme.colors.fontSecondary};
    }

    input {
      cursor: pointer;
      ${p => (p.$compactLabelView ? 'padding-top: 25px !important' : '')};
    }

    &:nth-child(3) {
      content: ${p => `'${p.$returnLabel}'`};
    }

    &:nth-child(3):before {
      content: ${p => `'${p.$returnLabel}'`};
    }
  }

  .ant-picker:not(.ant-picker-range) .ant-picker-input {
    flex-direction: row-reverse;

    .ant-picker-suffix {
      margin-inline-start: 0;
      margin-inline-end: 8px;

      &:after {
        ${textLargeMediumStyles};
        display: block;
        content: ${p => `'${p.$departureLabel}'`};
        position: relative;
        color: ${p => p.theme.colors.fontContrast};
        margin-inline-start: 16px;
        font-weight: 400;
      }
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    height: ${searchFormSizes.fieldHeightMobile}px;
  }

  .ant-picker-range {
    .ant-picker-suffix {
      margin-inline: 0 14px;
      order: -1;
      opacity: 1 !important;
    }
  }
`

export const CalenderIcon = styled(CalendarIcon)`
  grid-area: icon;
  fill: ${p => p.theme.colors.icon};
`
